<template>
  <div class="page-container">
    <LoadingIcon type="dark" />
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      LoadingIcon,
    },
    computed: {
      ...mapState('app', ['stripePricesIdMap', 'stripePlansIdMap']),
    },
    created() {

      const oldStripePrice = this.stripePricesIdMap[this.$route.query.oldStripePriceId || this.$route.query.old_stripe_price_id || null]
      const newStripePrice = this.stripePricesIdMap[this.$route.query.newStripePriceId || this.$route.query.new_stripe_price_id || null]

      this.$router.replace('/account/plans')
        .then(() => {

          if (!oldStripePrice || !newStripePrice) return

          const oldStripeProduct = this.stripePlansIdMap[oldStripePrice.product]
          const newStripeProduct = this.stripePlansIdMap[newStripePrice.product]

          if (!oldStripeProduct || !newStripeProduct) return

          this.$store.dispatch('modals/OPEN_MODAL', {
            name: 'StripeSubscriptionUpdateReturnModal',
            props: {
              oldStripeProduct,
              newStripeProduct,
            },
          })

        })
    },
  }

</script>

<style lang="stylus" scoped>

  .page-container
    @apply w-full
    @apply h-full

    @apply flex
    @apply items-center
    @apply justify-center

</style>
